<template>
  <div class="changeUserPasswordForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="password">Password:</label>
        <input
          type="password"
          v-model="password"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('password')
          }"
          name="password"
          id="password"
          placeholder="Password"
          v-validate="'required|min:7'"
          ref="password"
        />
        <div class="invalid-feedback">
          Please provide a valid password. Passwords must be alpha numeric and
          be at least 7 characters long.
        </div>
      </div>

      <div class="form-group">
        <label for="password">Password Confirmation:</label>
        <input
          type="password"
          v-model="password2"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('password2')
          }"
          name="password2"
          id="password_confirmation"
          placeholder="Confirm Password"
          v-validate="'required|confirmed:password'"
        />
        <div class="invalid-feedback">
          Please confirm password.
        </div>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        if (!isNaN(this.$route.params.id)) {
          var userPayload = {
            password: this.password
          };

          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/customers/" + this.$route.params.id,
              userPayload,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Password changed for user successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      password: ""
    };
  }
};
</script>
<style></style>
